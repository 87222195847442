import React from "react"

/** components */
import { ColorPalette } from "../Theme"

/** svg */
import NorhartIcon from "../../../assets/norhart-icon.svg"
import NorhartDarkCircleIcon from "../../../assets/norhart-dark-circle-icon.svg"

/** props */
interface Props {
  colorPalette: ColorPalette
}

/** const */
const PrivacyStatementSection: React.FC<Props> = (props) => {
  return (
    <section className="container-fluid sectionBaseColor pt-5 pb-5">
      <div className="container pl-3 pr-3">
        <div className="container pb-5"></div>
        <div className="text-center pb-2">
          <NorhartDarkCircleIcon />
        </div>

        <div
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl text-sm-center"
          style={{ fontSize: "15px" }}
        >
          Norhart Legal
        </div>
        <hr
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line mt-4 mb-4"
          style={{ borderColor: "rgba(0, 0, 0, 0.2)" }}
        />
        <div className="contentHeroSpace hidden-xs"></div>

        <h1 className="contentHeroTitle" style={{ color: "#365c70" }}>
          Norhart's Terms and Conditions
        </h1>
        <h2 className="contentHeroSubTitle" style={{ color: "rgba(83,118,136,0.8" }}>
          Learn more about the Norhart terms and policies that apply to our websites. At Norhart, we believe in being
          completely transparent end-to-end.
        </h2>

        <div className="contentHeroSubTitle text-start m-0" style={{ color: "rgba(83,118,136,0.8" }}>
          <blockquote className="blockquote text-start text-start legalTitle">Effective as of May 01, 2019</blockquote>

          <blockquote className="blockquote text-start legalLink">
            Agreement between User and <a href="https://www.norhart.com">https://www.norhart.com</a>
          </blockquote>

          <blockquote className="blockquote text-start legalLink">
            Welcome to <a href="https://www.norhart.com">https://www.norhart.com</a>. The{" "}
            <a href="https://www.norhart.com">https://www.norhart.com</a> website (the "Site") is comprised of various
            web pages operated by Norhart, LLC ("Norhart").{" "}
            <a href="https://www.norhart.com">https://www.norhart.com</a> is offered to you conditioned on your
            acceptance without modification of the terms, conditions, and notices contained herein (the "Terms"). Your
            use of <a href="https://www.norhart.com">https://www.norhart.com</a> constitutes your agreement to all such
            Terms. Please read these terms carefully, and keep a copy of them for your reference.
          </blockquote>

          <blockquote className="blockquote text-start legalLink">
            <a href="https://www.norhart.com">https://www.norhart.com</a> is a Corporate Site.
          </blockquote>

          <blockquote className="blockquote text-start">
            The Site provides information about Norhart and the apartments available for rent.
          </blockquote>

          <blockquote className="blockquote text-start legalTitle">Privacy</blockquote>
          <hr />
          <blockquote className="blockquote text-start legalLink">
            Your use of <a href="https://www.norhart.com">https://www.norhart.com</a> is subject to Norhart's Privacy
            Policy. Please review our Privacy Policy, which also governs the Site and informs users of our data
            collection practices.
          </blockquote>

          <blockquote className="blockquote text-start legalTitle">Electronic Communications</blockquote>
          <hr />
          <blockquote className="blockquote text-start legalLink">
            Visiting <a href="https://www.norhart.com">https://www.norhart.com</a> or sending emails to Norhart
            constitutes electronic communications. You consent to receive electronic communications and you agree that
            all agreements, notices, disclosures and other communications that we provide to you electronically, via
            email and on the Site, satisfy any legal requirement that such communications be in writing.
          </blockquote>

          <blockquote className="blockquote text-start legalTitle">Your Account</blockquote>
          <hr />
          <blockquote className="blockquote text-start">
            If you use this site, you are responsible for maintaining the confidentiality of your account and password
            and for restricting access to your computer, and you agree to accept responsibility for all activities that
            occur under your account or password. You may not assign or otherwise transfer your account to any other
            person or entity. You acknowledge that Norhart is not responsible for third party access to your account
            that results from theft or misappropriation of your account. Norhart and its associates reserve the right to
            refuse or cancel service, terminate accounts, or remove or edit content in our sole discretion.
          </blockquote>

          <blockquote className="blockquote text-start legalTitle">Children Under Thirteen</blockquote>
          <hr />
          <blockquote className="blockquote text-start legalLink">
            Norhart does not knowingly collect, either online or offline, personal information from persons under the
            age of thirteen. If you are under 18, you may use{" "}
            <a href="https://www.norhart.com">https://www.norhart.com</a> only with permission of a parent or guardian.
          </blockquote>

          <blockquote className="blockquote text-start legalTitle">
            Links to Third Party Sites/Third Party Services
          </blockquote>
          <hr />
          <blockquote className="blockquote text-start legalLink">
            <a href="https://www.norhart.com">https://www.norhart.com</a> may contain links to other websites ("Linked
            Sites"). The Linked Sites are not under the control of Norhart and Norhart is not responsible for the
            contents of any Linked Site, including without limitation any link contained in a Linked Site, or any
            changes or updates to a Linked Site. Norhart is providing these links to you only as a convenience, and the
            inclusion of any link does not imply endorsement by Norhart of the site or any association with its
            operators.
          </blockquote>

          <blockquote className="blockquote text-start legalLink">
            Certain services made available via <a href="https://www.norhart.com">https://www.norhart.com</a> are
            delivered by third party sites and organizations. By using any product, service or functionality originating
            from the <a href="https://www.norhart.com">https://www.norhart.com</a> domain, you hereby acknowledge and
            consent that Norhart may share such information and data with any third party with whom Norhart has a
            contractual relationship to provide the requested product, service or functionality on behalf of{" "}
            <a href="https://www.norhart.com">https://www.norhart.com</a> users and customers.
          </blockquote>

          <blockquote className="blockquote text-start legalTitle">
            No Unlawful or Prohibited Use/Intellectual Property
          </blockquote>
          <hr />
          <blockquote className="blockquote text-start legalLink">
            You are granted a non-exclusive, non-transferable, revocable license to access and use{" "}
            <a href="https://www.norhart.com">https://www.norhart.com</a> strictly in accordance with these terms of
            use. As a condition of your use of the Site, you warrant to Norhart that you will not use the Site for any
            purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could
            damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the
            Site. You may not obtain or attempt to obtain any materials or information through any means not
            intentionally made available or provided for through the Site.
          </blockquote>

          <blockquote className="blockquote text-start">
            All content included as part of the Service, such as text, graphics, logos, images, as well as the
            compilation thereof, and any software used on the Site, is the property of Norhart or its suppliers and
            protected by copyright and other laws that protect intellectual property and proprietary rights. You agree
            to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained
            in any such content and will not make any changes thereto.
          </blockquote>

          <blockquote className="blockquote text-start">
            You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create
            derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. Norhart
            content is not for resale. Your use of the Site does not entitle you to make any unauthorized use of any
            protected content, and in particular you will not delete or alter any proprietary rights or attribution
            notices in any content. You will use protected content solely for your personal use, and will make no other
            use of the content without the express written permission of Norhart and the copyright owner. You agree that
            you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express
            or implied, to the intellectual property of Norhart or our licensors except as expressly authorized by these
            Terms.
          </blockquote>

          <blockquote className="blockquote text-start legalTitle">International Users</blockquote>
          <hr />
          <blockquote className="blockquote text-start legalLink">
            The Service is controlled, operated and administered by Norhart from our offices within the USA. If you
            access the Service from a location outside the USA, you are responsible for compliance with all local laws.
            You agree that you will not use the Norhart Content accessed through{" "}
            <a href="https://www.norhart.com">https://www.norhart.com</a> in any country or in any manner prohibited by
            any applicable laws, restrictions or regulations.
          </blockquote>

          <blockquote className="blockquote text-start legalTitle">Indemnification</blockquote>
          <hr />
          <blockquote className="blockquote text-start">
            You agree to indemnify, defend and hold harmless Norhart, its officers, directors, employees, agents and
            third parties, for any losses, costs, liabilities and expenses (including reasonable attorney's fees)
            relating to or arising out of your use of or inability to use the Site or services, any user postings made
            by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or
            your violation of any applicable laws, rules or regulations. Norhart reserves the right, at its own cost, to
            assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which
            event you will fully cooperate with Norhart in asserting any available defenses.
          </blockquote>

          <blockquote className="blockquote text-start legalTitle">Arbitration</blockquote>
          <hr />
          <blockquote className="blockquote text-start">
            In the event the parties are not able to resolve any dispute between them arising out of or concerning these
            Terms and Conditions, or any provisions hereof, whether in contract, tort, or otherwise at law or in equity
            for damages or any other relief, then such dispute shall be resolved only by final and binding arbitration
            pursuant to the Federal Arbitration Act, conducted by a single neutral arbitrator and administered by the
            American Arbitration Association, or a similar arbitration service selected by the parties, in a location
            mutually agreed upon by the parties. The arbitrator's award shall be final, and judgment may be entered upon
            it in any court having jurisdiction. In the event that any legal or equitable action, proceeding or
            arbitration arises out of or concerns these Terms and Conditions, the prevailing party shall be entitled to
            recover its costs and reasonable attorney's fees. The parties agree to arbitrate all disputes and claims in
            regards to these Terms and Conditions or any disputes arising as a result of these Terms and Conditions,
            whether directly or indirectly, including Tort claims that are a result of these Terms and Conditions. The
            parties agree that the Federal Arbitration Act governs the interpretation and enforcement of this provision.
            The entire dispute, including the scope and enforceability of this arbitration provision shall be determined
            by the Arbitrator. This arbitration provision shall survive the termination of these Terms and Conditions.
          </blockquote>

          <blockquote className="blockquote text-start legalTitle">Class Action Waiver</blockquote>
          <hr />
          <blockquote className="blockquote text-start">
            Any arbitration under these Terms and Conditions will take place on an individual basis; class arbitrations
            and class/representative/collective actions are not permitted. THE PARTIES AGREE THAT A PARTY MAY BRING
            CLAIMS AGAINST THE OTHER ONLY IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
            PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY
            GENERAL ACTION AGAINST THE OTHER. Further, unless both you and Norhart agree otherwise, the arbitrator may
            not consolidate more than one person's claims, and may not otherwise preside over any form of a
            representative or class proceeding.
          </blockquote>

          <blockquote className="blockquote text-start legalTitle">Liability Disclaimer</blockquote>
          <hr />
          <blockquote className="blockquote text-start">
            THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE
            INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. NORHART, LLC
            AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.
          </blockquote>

          <blockquote className="blockquote text-start">
            NORHART, LLC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY,
            TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON
            THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE,
            PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND.
            NORHART, LLC AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS
            INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR
            CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
          </blockquote>

          <blockquote className="blockquote text-start">
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL NORHART, LLC AND/OR ITS SUPPLIERS BE
            LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES
            WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY
            WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR
            RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE,
            PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF
            THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF NORHART, LLC
            OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO
            NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE
            LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE
            TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.
          </blockquote>

          <blockquote className="blockquote text-start legalTitle">Termination/Access Restriction</blockquote>
          <hr />
          <blockquote className="blockquote text-start">
            Norhart reserves the right, in its sole discretion, to terminate your access to the Site and the related
            services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this
            agreement is governed by the laws of the State of Minnesota and you hereby consent to the exclusive
            jurisdiction and venue of courts in Minnesota in all disputes arising out of or relating to the use of the
            Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of
            these Terms, including, without limitation, this section.
          </blockquote>

          <blockquote className="blockquote text-start">
            You agree that no joint venture, partnership, employment, or agency relationship exists between you and
            Norhart as a result of this agreement or use of the Site. Norhart's performance of this agreement is subject
            to existing laws and legal process, and nothing contained in this agreement is in derogation of Norhart's
            right to comply with governmental, court and law enforcement requests or requirements relating to your use
            of the Site or information provided to or gathered by Norhart with respect to such use. If any part of this
            agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited
            to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable
            provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent
            of the original provision and the remainder of the agreement shall continue in effect.
          </blockquote>

          <blockquote className="blockquote text-start">
            Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and
            Norhart with respect to the Site and it supersedes all prior or contemporaneous communications and
            proposals, whether electronic, oral or written, between the user and Norhart with respect to the Site. A
            printed version of this agreement and of any notice given in electronic form shall be admissible in judicial
            or administrative proceedings based upon or relating to this agreement to the same extent and subject to the
            same conditions as other business documents and records originally generated and maintained in printed form.
            It is the express wish to the parties that this agreement and all related documents be written in English.
          </blockquote>

          <blockquote className="blockquote text-start legalTitle">SMS Messages</blockquote>
          <hr />
          <blockquote className="blockquote text-start legalLink">
            Norhart will send SMS messages from time to time to keep you updated on new and upcoming deals and offers!
            <p />
            <ul>
              <li>
                You can cancel the SMS service at any time. Just text "STOP" to the short code. After you send the SMS
                message "STOP" to us, we will send you an SMS message to confirm that you have been unsubscribed. After
                this, you will no longer receive SMS messages from us. If you want to join again, just sign up as you
                did the first time and we will start sending SMS messages to you again.
              </li>
              <p />
              <li>
                If you are experiencing issues with the messaging program you can reply with the keyword HELP for more
                assistance, or you can get help directly at <a href="mailto:contact@norhart.com">contact@norhart.com</a>
              </li>
              <p />
              <li>Carriers are not liable for delayed or undelivered messages</li>
              <p />
              <li>
                As always, message and data rates may apply for any messages sent to you from us and to us from you. You
                will receive message frequency. If you have any questions about your text plan or data plan, it is best
                to contact your wireless provider.
              </li>
              <p />
              <li>
                If you have any questions regarding privacy, please read our privacy policy:{" "}
                <a href="https://www.norhart.com/legal/privacy/">https://www.norhart.com/legal/privacy/</a>
              </li>
            </ul>
          </blockquote>

          <blockquote className="blockquote text-start legalTitle">
            Opt-Out & Unsubscribe from Third Party Communications
          </blockquote>
          <hr />
          <blockquote className="blockquote text-start legalLink">
            Opt-Out & Unsubscribe from Third Party Communications We respect your privacy and give you an opportunity to
            opt-out of receiving announcements of certain information. Users may opt-out of receiving any or all
            communications from third-party partners of Norhart by contacting us here:
            <p />
            Email: <a href="mailto:contact@norhart.com">contact@norhart.com</a>
            <p />
            Phone: <a href="tel:651-689-4830">(651) 689-4830</a>
          </blockquote>

          <blockquote className="blockquote text-start legalTitle">Changes to Terms</blockquote>
          <hr />
          <blockquote className="blockquote text-start legalLink">
            Norhart reserves the right, in its sole discretion, to change the Terms under which{" "}
            <a href="https://www.norhart.com">https://www.norhart.com</a> is offered. The most current version of the
            Terms will supersede all previous versions. Norhart encourages you to periodically review the Terms to stay
            informed of our updates.
          </blockquote>

          <blockquote className="blockquote text-start legalLink">
            <address className="list-group ms-3">
              <li>Norhart, LLC</li>
              <li>1081 4th St SW</li>
              <li>Suite: #400</li>
              <li>Forest Lake, Minnesota 55025</li>
              <li>
                Email: <a href="mailto:contact@norhart.com">contact@norhart.com</a>
              </li>
              <li>
                Phone: <a href="tel:651-689-4830">(651) 689-4830</a>
              </li>
            </address>
          </blockquote>
        </div>
      </div>
    </section>
  )
}
/** export */
export default PrivacyStatementSection
